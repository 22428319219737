import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import AppDrawer from './Drawer';

const Layout = (props) => {
  const [drawer, setDrawer] = useState(false);
  const { children } = props;
  const handleToggleDrawer = () => setDrawer((st) => !st);

  return (
    <>
      <Navbar toggleDrawer={handleToggleDrawer} />
      <AppDrawer open={drawer} toggleDrawer={handleToggleDrawer} />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
