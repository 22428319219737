import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';

const user = {
  state: {
    list: null,
    listWithSportPlace: null,
    excelUrl: null,
    error: null,
    status: '',
    message: '',
    views: null,
  },
  reducers: {
    // List
    list: (state) => ({
      ...state, list: null, status: 'waiting', error: null,
    }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Show all users with 0 coins
    zeroCoins: (state) => ({ ...state, status: 'waiting', error: null }),
    // Download excel with users info
    excel: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    zeroCoinsExcel: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    addCoins: (state) => ({ ...state, status: 'waiting', error: null }),
    addCoinsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    addCoinsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // List with sport place
    listWithSportPlace: (state) => ({
      ...state, listWithSportPlace: null, status: 'waiting', error: null,
    }),
    listWithSportPlaceSuccess: (state, res) => ({
      ...state,
      status: 'received',
      listWithSportPlace: res,
    }),
    listWithSportPlaceFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Download excel with users info
    excelWithSportPlace: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelWithSportPlaceSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelWithSportPlaceFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Views
    views: (state) => ({
      ...state, views: null, status: 'waiting', error: null,
    }),
    viewsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      views: res,
    }),
    viewsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Download excel with users views
    excelViews: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelViewsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelViewsFailed: (state, error) => ({ ...state, status: 'failed', error }),
  },
  effects: (dispatch) => ({
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/users', values).then((res) => {
        dispatch.user.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    zeroCoins(payload) {
      const { enqueueSnackbar } = payload;

      ax.get('/reports/users-credits-zero').then((res) => {
        dispatch.user.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excel(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/users', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.user.excelSuccess(url);
        downloadExcel(url, 'Usuarios.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    zeroCoinsExcel(payload) {
      const { enqueueSnackbar } = payload;

      ax.get('/reports/excel/users-credits-zero', { responseType: 'blob' }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.user.excelSuccess(url);
        downloadExcel(url, 'UsuariosCeroMonedas.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    addCoins(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/reports/save-credit-personal', values).then((res) => {
        dispatch.user.addCoinsSuccess(res.data);
        enqueueSnackbar('Monedas asignadas', { variant: 'success' });
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.addCoinsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    listWithSportPlace(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/users-team-sport-place', values).then((res) => {
        dispatch.user.listWithSportPlaceSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.listWithSportPlaceFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelWithSportPlace(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/users-team-sport-place', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.user.excelWithSportPlaceSuccess(url);
        downloadExcel(url, 'UsuariosConComplejo.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.excelWithSportPlaceFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    views(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/users/views', values).then((res) => {
        dispatch.user.viewsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.viewsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelViews(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/users/views', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.user.excelViewsSuccess(url);
        downloadExcel(url, 'VistasPorUsuario.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.user.excelViewsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    }
  }),
};

export default user;
