import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FileUploadResult from './UploadResult';

const FileUpload = ({
  label,
  id,
  accept,
  fileChange,
  multiple,
  icon,
  className,
  disablePreview,
  maximumFiles,
  onInit,
}) => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState('');

  const setFilesResultHandler = (event) => {
    const filesList = event.target.files;

    if (filesList && multiple) {
      setFiles(
        typeof maximumFiles === 'number'
          ? Object.values(filesList).slice(0, maximumFiles)
          : Object.values(filesList),
      );
      fileChange(id, Object.values(filesList));
    } else if (filesList && !multiple) {
      setFile(filesList[0]);
      fileChange(id, filesList[0]);
    }
  };

  const cleanInputFileHandler = (event) => {
    const control = event.target;
    control.value = null;
  };

  const deleteFileHandler = (fileName) => {
    if (multiple) {
      const updatedFiles = files.filter((fileItem) => fileName !== fileItem.name);
      setFiles(updatedFiles);
      fileChange(id, updatedFiles);
    } else {
      setFile('');
      fileChange(id, '');
    }
  };

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <>
      <label
        className={`flex flex-col items-center justify-center border border-dashed rounded border-grey p-2 ${className} relative`}
        htmlFor={id}
      >
        {icon}

        {label && <p className='mt-1 mb-0 text-sm font-medium text-grey'>{label}</p>}

        <p className='m-0 text-xs text-center cursor-pointer text-grey'>
          <span className='underline text-primary-500'>Selecciona un archivo</span>
        </p>

        <input
          id={id}
          type='file'
          className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
          accept={accept}
          onInput={setFilesResultHandler}
          onClick={cleanInputFileHandler}
          multiple={multiple}
        />
      </label>

      {!disablePreview && (file || files.length > 0) && (
        <FileUploadResult
          files={files}
          file={file || {}}
          multiple={multiple}
          onDelete={deleteFileHandler}
        />
      )}
    </>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  id: PropTypes.string.isRequired,
  accept: PropTypes.string,
  fileChange: PropTypes.func,
  onInit: PropTypes.func,
  multiple: PropTypes.bool,
  disablePreview: PropTypes.bool,
  className: PropTypes.string,
  maximumFiles: PropTypes.number,
};

FileUpload.defaultProps = {
  label: '',
  multiple: false,
  accept: 'image/*',
  icon: null,
  className: '',
  disablePreview: false,
  maximumFiles: null,
  fileChange: () => null,
  onInit: () => null,
};

export default FileUpload;
