import axios from 'axios';

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

ax.interceptors.request.use(
  (request) => {
    const session = JSON.parse(localStorage.getItem('session'));
    const updatedReq = { ...request };

    if (session && request.url.indexOf('auth') === -1) {
      updatedReq.headers.Authorization = `Bearer ${session.token}`;
    }

    return updatedReq;
  },
  (error) => Promise.reject(error),
);

export default ax;
