import React from 'react';
import PropTypes from 'prop-types';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';
import Utils from '../../Utils';

const FileUploadResultItem = React.memo(({
  name, size, onDelete, file, type,
}) => (
  <li className='flex items-center pb-2 mb-2 border-b'>
    {type.indexOf('image') >= 0 ? (
      <div
        className='w-16 h-12 rounded'
        style={{ background: `transparent url(${URL.createObjectURL(file)}) center/cover no-repeat` }}
      />
    ) : (
      <InsertDriveFileIcon />
    )}

    <div className='px-4 mr-auto text-xs text-grey-medium'>
      <p className='m-0 font-light'>{name}</p>
      <p className='m-0'>{Utils.bytesToSize(size)}</p>
    </div>

    <button type='button' onClick={() => onDelete(name)}>
      <CloseIcon />
    </button>
  </li>
));

FileUploadResultItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  file: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default FileUploadResultItem;
