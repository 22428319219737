import React from 'react';
import PropTypes from 'prop-types';
import FileUploadResultItem from './UploadResultItem';

const FileUploadResult = React.memo(({
  files, file, multiple, onDelete,
}) => (
  <>
    <p className='my-3 text-sm font-bold text-grey-dark'>Uploaded files</p>
    <ul className='mt-3'>
      {multiple ? (
        files.map((fileItem) => (
          <FileUploadResultItem
            name={fileItem.name}
            size={fileItem.size}
            key={fileItem.name}
            type={fileItem.type}
            onDelete={() => onDelete(fileItem.name)}
            file={fileItem}
          />
        ))
      ) : (
        <FileUploadResultItem
          name={file.name}
          size={file.size}
          onDelete={onDelete}
          type={file.type}
          file={file}
        />
      )}
    </ul>
  </>
));

FileUploadResult.defaultProps = {
  files: [],
  file: null,
};

FileUploadResult.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  file: PropTypes.objectOf(PropTypes.any),
  multiple: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FileUploadResult;
