import React from 'react';
import {
  AppBar, Toolbar, IconButton, Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../assets/img/logo.png';

const Navbar = (props) => {
  const { toggleDrawer } = props;
  const dispatch = useDispatch();
  const signout = () => dispatch({ type: 'auth/signout' });

  return (
    <AppBar position='static'>
      <Toolbar>
        <IconButton
          className='mr-4 outline-none'
          edge='start'
          color='inherit'
          aria-label='menu'
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>

        <img className='w-20' src={logo} alt='PITZ' />

        <Button
          className='ml-auto outline-none'
          size='small'
          type='button'
          onClick={signout}
        >
          Cerrar sesión
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  toggleDrawer: PropTypes.func,
};

Navbar.defaultProps = {
  toggleDrawer: () => null,
};

export default Navbar;
