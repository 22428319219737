import React from 'react';
import { Link } from '@reach/router';
import { Typography } from '@material-ui/core';

const Error = () => (
  <div className='flex flex-col items-center justify-center min-h-screen bg-dark'>
    <Typography paragraph align='center' color='textPrimary' variant='h3'>
      ERROR 404
    </Typography>

    <Typography paragraph align='center' color='textSecondary' variant='body1'>
      No pudimos encontrar lo que buscas.
    </Typography>

    <Link className='text-white' to='/'>Regresar al inicio</Link>
  </div>
);

export default Error;
