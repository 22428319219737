import { navigate } from '@reach/router';
import ax from '../../axios';

const auth = {
  state: {
    user: null,
    token: null,
    error: null,
    status: '',
  },
  reducers: {
    // SIGN IN
    signin: (state) => ({ ...state, status: 'waiting', error: null }),
    signinSuccess: (state, res) => ({
      ...state,
      status: 'received',
      token: res.token,
      user: res.admin,
    }),
    signinFailed: (state, error) => ({ ...state, status: 'failed', error }),

    // RECOVER SESSION
    recoverUserSession: (state, payload) => ({
      ...state,
      token: payload.token,
      user: payload.user,
      roles: payload.roles,
      status: 'received',
    }),
  },
  effects: (dispatch) => ({
    signin(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/login', values).then((res) => {
        dispatch.auth.signinSuccess(res.data.data);
        enqueueSnackbar('Sesión iniciada correctamente', { variant: 'success' });
        navigate('/');
        localStorage.setItem(
          'session',
          JSON.stringify({
            token: res.data.data.token,
            user: res.data.data.admin,
          }),
        );
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.auth.signinFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    // SIGN OUT
    signout() {
      localStorage.removeItem('session');
      dispatch({ type: 'RESET_APP' });
      navigate('/');
    },
  }),
};

export default auth;
