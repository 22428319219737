import React from 'react';
import {
  Typography, Button, Card, LinearProgress, TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import logo from '../assets/img/logo.png';

const SignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm({ submitFocusError: true });
  const authState = useSelector((st) => ({ status: st.auth.status, error: st.auth.error }));
  const dispatch = useDispatch();

  return (
    <main className='flex flex-col items-center justify-center min-h-screen bg-dark'>
      {authState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

      <Card className='max-w-sm p-8 mx-auto'>
        <div className='flex items-center justify-center mb-8'>
          <img className='w-24' src={logo} alt='Pitz' />
        </div>

        <Typography className='mt-0 mb-12 text-center' variant='h5' component='h1'>
          Iniciar sesión
        </Typography>

        <form
          className='flex-col'
          onSubmit={handleSubmit((values) => dispatch({ type: 'auth/signin', payload: { values, enqueueSnackbar } }))}
        >
          <TextField
            className='mb-6'
            error={!!errors.email}
            label='Correo*'
            name='email'
            type='email'
            variant='outlined'
            fullWidth
            helperText={errors.email && errors.email.message}
            inputProps={{
              ref: register({
                required: 'El campo es requerido.',
                // prettier-ignore
                pattern: { value: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/, message: 'Formato invalido.' },
              }),
            }}
          />

          <TextField
            className='mb-6'
            error={!!errors.password}
            label='Contraseña*'
            name='password'
            type='password'
            variant='outlined'
            fullWidth
            helperText={errors.password && errors.password.message}
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
          />

          <div className='flex items-center justify-center'>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              disabled={authState.status === 'waiting'}
            >
              Iniciar sesión
            </Button>
          </div>

          {authState.error && (
            <div className='mt-8 errors'>
              <Typography paragraph align='center' color='error' variant='body2'>
                {authState.error.data.message}
              </Typography>
            </div>
          )}
        </form>
      </Card>
    </main>
  );
};

export default SignIn;
